import * as React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Grid,
  Box,
} from '@mui/material';

import {
  Button,
} from 'gatsby-theme-material-ui';

import EmailCard from "../components/EmailCard"
import TemplatePreview from "../components/TemplatePreview"
import ViewMoreDialog from "../components/ViewMoreDialog"

function TemplateRow({
  templates,
  totalTemplates,
  linkTitleString,
  sectionTitleComponent,
  sectionSubTitleComponent,
  viewMoreTitle,
  collectionTemplatePagePath,
  individualPagePath,
}) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [previewingTemplateIndex, setPreviewingTemplateIndex] = React.useState(null);
  const [previewDialogOpen, setPreviewDialogOpen] = React.useState(false);
  const [viewMoreHtmlDialogOpen, setViewMoreHtmlDialogOpen] = React.useState(false);

  const keyCheck = React.useCallback((event) => {
    const keyCode = event.keyCode; 

    if (keyCode === 37) {
      if (previewDialogOpen) {
        onHtmlPreviewLastClicked();
      } else if (viewMoreHtmlDialogOpen) {
        onHtmlViewMorelastClicked();
      }
    } else if (keyCode === 39) {
      if (previewDialogOpen) {
        onHtmlPreviewNextClicked();
      }
    }
  },[previewDialogOpen, viewMoreHtmlDialogOpen, previewingTemplateIndex]);

  React.useEffect(() => {
    window.addEventListener('keyup', keyCheck);

    return () => window.removeEventListener('keyup', keyCheck);
  }, [keyCheck]);


  const onHtmlPreviewLastClicked = () => {
    if (previewingTemplateIndex !== 0) {
      setPreviewingTemplateIndex(previewingTemplateIndex - 1)
    }
  };

  const onHtmlPreviewNextClicked = () => {
    if (previewingTemplateIndex === templates.length - 1) {
      setPreviewDialogOpen(false);
      setViewMoreHtmlDialogOpen(true);
    } else {
      setPreviewingTemplateIndex(previewingTemplateIndex + 1)
    }
  };

  const onHtmlViewMorelastClicked = () => {
    setViewMoreHtmlDialogOpen(false);
    setPreviewDialogOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          pb: 4
        }}
      >
        <Box>
          {sectionTitleComponent}
          {sectionSubTitleComponent}
        </Box>
        {!smallScreen && <Box sx={{pl:1}}>
          <Button
            variant="outlined"
            sx={{width: '150px'}}
            to={collectionTemplatePagePath}
            title={`The ${linkTitleString} template collection page`}
          >
            {`View ${totalTemplates} More`}
          </Button>
        </Box>}
      </Box>
      <Grid container spacing={4}>
        {templates.map((template, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <EmailCard
              template={template}
              selected={previewingTemplateIndex === index}
              templateLink={individualPagePath.replace('{name}', template.id)}
              onPreviewTemplate={() => {
                if (smallScreen) {
                  navigate(individualPagePath.replace('{name}', template.id))
                } else {
                  setPreviewingTemplateIndex(index);
                  setPreviewDialogOpen(true);
                }
              }}
            />
          </Grid>
        ))}
      </Grid>
      {smallScreen && <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          pt: 5,
        }}
      >
        <Button
          variant="outlined"
          sx={{width: '150px'}}
          to={collectionTemplatePagePath}
        >
          {`View ${totalTemplates} More`}
        </Button>
      </Box>}
      <TemplatePreview
        individualPagePath={individualPagePath}
        template={previewingTemplateIndex !== null ? templates[previewingTemplateIndex] : null}
        open={previewDialogOpen}
        onClose={() => {
          setPreviewDialogOpen(false);
        }}
        lastDisabled={previewingTemplateIndex === 0}
        onNextClicked={() => onHtmlPreviewNextClicked()}
        onLastClicked={() => onHtmlPreviewLastClicked()}
      />
      <ViewMoreDialog
        open={viewMoreHtmlDialogOpen}
        onClose={() => setViewMoreHtmlDialogOpen(false)}
        onLastClicked={() => onHtmlViewMorelastClicked}
        title={viewMoreTitle}
        totalTemplates={totalTemplates}
        buttonPath={collectionTemplatePagePath}
      />
    </>
  );
}

TemplateRow.propTypes = {

};

export default TemplateRow;