import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Fab,
  IconButton,
  Dialog,
  Box,
  DialogContent,
} from '@mui/material';

import {
  Button,
} from 'gatsby-theme-material-ui';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';



function ViewMoreDialog({
  open,
  onClose,
  onLastClicked,
  title,
  buttonPath,
  totalTemplates
}) {

  return (
    <>
      {open && <Fab
        sx={{
          position: 'absolute',
          top: '49%',
          left: '50px',
          zIndex: '10000'
        }}
        onClick={onLastClicked}
      >
        <ChevronLeft />
      </Fab>}
      <Dialog
        onClose={onClose}
        open={open}
        maxWidth='md'
        PaperProps={{
          sx:{
            width: '100%',
          }
        }}
      >
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '300px'
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              position:' absolute',
              top: '2px',
              right: '2px',
            }}
          >
            <Close />
          </IconButton>
          <Typography
            sx={{
              fontSize: '1.8rem',
              textAlign: 'center',
            }}
          >
            {title}
          </Typography>
          <Button
            variant='contained'
            size='large'
            sx={{
              width: '300px',
              marginTop: '30px'
            }}
            to={buttonPath}
            endIcon={<ChevronRight />}
          >
            {`View ${totalTemplates} More Templates`}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

ViewMoreDialog.propTypes = {

};

export default ViewMoreDialog;