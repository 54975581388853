import * as React from "react"

import {
  Grid,
  Box,
  Container,
  Typography,
} from '@mui/material';

import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import ArticleCard from "../components/ArticleCard"
import TemplateRow from "../components/TemplateRow"

import * as helpers from "../helpers"
import CONSTS from "../consts"


const HomePage = ({
  data
}) => {
  const emailTemplates = helpers.normalizeTemplatesData(data.emailTemplates.nodes);
  const emailTemplatesPlainText = helpers.normalizeTemplatesData(data.emailTemplatesPlainText.nodes);

  return (
    <Layout>
      <Box
        sx={{
          bgcolor: 'background.paper',
          backgroundColor: '#d7d7d7',
          backgroundImage: 'url("/html_email_and_text_email_header.jpg")',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <Box
          sx={{
            backgroundColor: '#ffffffd4',
            pt: 8,
            pb: 6,
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              mb: 8,
            }}
          >
            <Typography
              variant="h1"
              gutterBottom
              sx={{
                textAlign: 'center',
                fontSize: ['2.6rem', '4rem'],
              }}
            >
              Review Request Emails
            </Typography>
            <Typography
              variant="h5"
              align="center"
              sx={{
                color: '#6c6c6c',
              }}
            >
              Collect more reviews, attract more customers and grow your business.
            </Typography>
            <Typography
              component="p"
              align="center"
              sx={{
                maxWidth: 'none',
                marginTop: '30px',
                fontSize: '1.2rem',
              }}
            >
              All templates are free to download, no account required.
            </Typography>
          </Container>
        </Box>
      </Box>
      
      <Container
        sx={{
          py: 8,
          px: [1, 2, 2, 4],
        }}
        maxWidth="lg"
      >
        <TemplateRow
          templates={emailTemplates}
          totalTemplates={data.emailTemplateCount.totalCount}
          linkTitleString='HTML'
          sectionTitleComponent={<Typography
            variant="h1"
            sx={{
              fontWeight: '500',
              fontSize: '2.5rem',
            }}
          >
            {'HTML Email Templates'}
          </Typography>}
          sectionSubTitleComponent={<>
            <Typography
              component='p'
              sx={{
                fontSize: '1.2rem',
                marginBottom: '20px'
              }}
            >
              Eye catching, professional email templates that motivate customers to post reviews.
            </Typography>
            <Typography
              component='p'
            >
              Browse our collection to find the best template for your business.
            </Typography>
          </>}
          viewMoreTitle='Head over to the HTML template page for more'
          collectionTemplatePagePath={CONSTS.PATHS.HTML_EMAIL_TEMPLATE_COLLECTION}
          individualPagePath={CONSTS.PATHS.INDIVIDUAL_HTML_EMAIL_TEMPLATE}
        />
      </Container>

      <Container sx={{ py: 8 }} maxWidth="lg">
        <TemplateRow
          templates={emailTemplatesPlainText}
          totalTemplates={data.emailTemplatePlainTextCount.totalCount}
          linkTitleString='plain text'
          sectionTitleComponent={<Typography
            variant="h1"
            sx={{
              fontWeight: '500',
              fontSize: '2.5rem',
            }}
          >
            {'Plain Text Emails'}
          </Typography>}
          sectionSubTitleComponent={<>
            <Typography
              component='p'
              sx={{
                fontSize: '1.3rem',
                marginBottom: '20px'
              }}
            >
              Simple and effective templates that are easy for anyone to send.
            </Typography>
            <Typography
              component='p'
            >
              Quickly and easily copy one of our examples into an email, send it and get reviews.
            </Typography>
          </>}
          viewMoreTitle='Head over to the plain text template page for more'
          collectionTemplatePagePath={CONSTS.PATHS.PLANE_TEXT_EMAIL_TEMPLATE_COLLECTION}
          individualPagePath={CONSTS.PATHS.INDIVIDUAL_PLANE_TEXT_EMAIL_TEMPLATE}
        />
      </Container>
      

      <Container sx={{ py: 8 }} maxWidth="lg">
      <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            pb: 4
          }}
        >
          <Box>
            <Typography
              variant="h1"
              sx={{
                fontWeight: '500',
                fontSize: '2.5rem',
              }}
            >
              Featured Content
            </Typography>
            <Typography
              component='p'
              sx={{
                fontSize: '1.3rem',
                marginBottom: '20px'
              }}
            >
              Packed with advice from review management experts.
            </Typography>
            <Typography
              component='p'
            >
              Our articles and free templates combined with your passion is everything you need for your business to be successful with review requests.
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={4}>
          {data.homePagePosts.nodes.map((post) => {
            return (
              <ArticleCard
                post={post}
              />
            );
          })}
        </Grid>
      </Container>
    </Layout>
  );
};

export const Head = ({location}) => <Seo
  title="250+ Free Review Request Email Templates for Business Owners | Review Request Emails"
  description="Improve your customer feedback collection process with one of our review request email templates. Our professionally designed options will make a lasting impression. Choose your favorite and start collecting valuable insights today! No Account Required."
  url='' // so there is no trailing slash
>
  
</Seo>

export const query = graphql`
  query allTemplateHomePageQuery {
    homePagePosts: allMdx(sort: {order: ASC, fields: frontmatter___menu}, filter: {frontmatter: {home: {eq: true}}}) {
      nodes {
        frontmatter {
          title
          author
          blurb
        }
        fields {
          pagePath
        }
      }
    }
    emailTemplates: allDirectory(limit: 3 filter: {sourceInstanceName: {eq: "emailTemplates"}, relativeDirectory: {eq: ""}, relativePath: {in: ["ht1a02b003d", "ht1a02c003f", "ht1b02d003g"]}}) {
      nodes {
        relativePath
        childFiles {
          name
          childTemplateData {
            description
            variables {
              description
              variable
              example
            }
          }
          childPlainText {
            content
          }
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    emailTemplateCount: allDirectory(
      filter: {sourceInstanceName: {eq: "emailTemplates"}, relativeDirectory: {eq: ""}}
    ) {
      totalCount
    }
    emailTemplatesPlainText: allDirectory(limit: 3 filter: {sourceInstanceName: {eq: "emailTemplatesPlainText"}, relativeDirectory: {eq: ""}}) {
      nodes {
        relativePath
        childFiles {
          name
          childTemplateData {
            description
            variables {
              description
              variable
              example
            }
          }
          childPlainText {
            content
          }
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    emailTemplatePlainTextCount: allDirectory(
      filter: {sourceInstanceName: {eq: "emailTemplatesPlainText"}, relativeDirectory: {eq: ""}}
    ) {
      totalCount
    }
  }
`;

export default HomePage
