import * as React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Link,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia
} from '@mui/material';

import { navigate } from 'gatsby';


function ArticleCard({post}) {

  return (
    <Grid item xs={12} md={6}>
      <Card sx={{ display: 'flex', backgroundColor: '#f9f9f9' }}>
        <CardActionArea
          onClick={() => {
            navigate(post.fields.pagePath)
          }}
        >
          <CardContent sx={{ flex: 1 }}>
            <Typography
              variant="h3"
              className='article_title'
              sx={{
                paddingBottom: '20px',
                fontSize: '1.5rem',
                fontWeight: '600',
              }}
            >
              {post.frontmatter.title}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {`${post.frontmatter.blurb} `}
              <Link
              href={post.fields.pagePath}
              title={`${post.frontmatter.title} article`}
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Continue reading...
            </Link>
            </Typography>

          </CardContent>
          <CardMedia
            component="img"
            sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
            image={post.image}
            alt={post.imageLabel}
          />
        </CardActionArea>
      </Card>
    </Grid>
  );
}

ArticleCard.propTypes = {
  post: PropTypes.shape({
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default ArticleCard;